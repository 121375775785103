export function customFetch (url, method, body, auth, callback, onError) {
    if (method === 'POST') {
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user.accessToken}`
            },
            body: JSON.stringify(body)
        }).then((res) => res.json())
        .then((data) => {
            callback(data);
        }).catch((err) => {
            onError(err);
        });
    } else {
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user.accessToken}`
            },
        }).then((res) => res.json())
        .then((data) => {
            callback(data);
        }).catch((err) => {
            onError(err);
        });
    }
}


export function fetchCorrectOrder (auth, callback, onError) {
    fetch('/api/getCorrectOrder', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.user.accessToken}`
        }
    }).then((res) => res.json())
    .then((data) => {
        callback(data);
    }).catch((err) => {
        onError(err);
    });
}


export function fetchDatabase (auth, callback, onError) {
    fetch('/api/getDatabase', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.user.accessToken}`
        }
    }).then((res) => res.json())
    .then((data) => {
        callback(data);
    }).catch((err) => {
        onError(err);
    });
}