// src/auth/AuthContext.js
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const admins = ["heboba", "admin"];
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);

  //Here check if user is already logged in
  useEffect(() => {
    const savedUser = localStorage.getItem('token');

    if (savedUser) {
      const userData = JSON.parse(savedUser);
      if (userData.expires > Date.now()) {
        setUser(userData);

        // Refresh token if it expires in less then 4 days
        if (userData.expires - Date.now() < 4 * 24 * 60 * 60 * 1000) {
          // Refresh token
          console.log('Refresh token');
          fetch('/api/refreshToken', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${userData.accessToken}`
            }
          }).catch((err) => {
            alert('Error refreshing token: ' + err);
          })
            .then((res) => res.json())
            .then((data) => {
              const newUserData = { name: userData.name, accessToken: data.accessToken, expires: Date.now() + 7 * 24 * 60 * 60 * 1000 }; // 7 days
              localStorage.setItem('token', JSON.stringify(newUserData));
              setUser(newUserData);
            });
        }
      } else {
        localStorage.removeItem('token');
      }
    }
    setLoading(false);
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      // Функция для проверки аутентификации
      checkAuthStatus();
    }, 300000); // Проверка каждые 5 минут

    // Функция для очистки интервала
    return () => clearInterval(interval);
  }, []);

  const checkAuthStatus = () => {
    if (user) {
      if (user.expires < Date.now()) {
        logout();
      }
    }
  }



  const login = async (username, password) => {

    const response = await fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });


    if (response.status == 403) {
      alert('Invalid username or password');
      throw new Error('Invalid username or password');
    }


    const data = await response.json();

    const userData = { name: username, accessToken: data.accessToken, expires: Date.now() + 7 * 24 * 60 * 60 * 1000 }; // 7 days
    localStorage.setItem('token', JSON.stringify(userData));
    setUser(userData);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('token');
  };

  const isAdmin = useCallback(() => {
    return user && admins.includes(user.name);
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, isLoading, login, logout, isAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
