import React, { useState } from 'react';

const styles = {
    thtInfoBox: {
        position: 'absolute',
        right: '-44px',
        width: '38px',
        height: '38px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    thtInfo: {
        fontSize: '28px',
        cursor: 'pointer'
    },
    hoverInfo: {
        position: 'absolute',
        backgroundColor: 'white',
        color: 'black',
        border: '1px solid #000',
        borderRadius: '6px',
        padding: '5px 10px',
        top: '40px',
        transform: 'translateX(-50%)',
        zIndex: '10',
        boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
        textWrap: 'nowrap'
    }
};

// The main functional component to display THT information based on conditions.
export default function THTInfo({ item }) {
    // State for hover effect
    const [hoverVisible, setHoverVisible] = useState(false);

    const icons = {
        0: '',          // No icon for level 0
        1: '❔',        // Level 1
        2: '⚠️',       // Level 2
        3: '❗'         // Level 3
    };

    // Helper function to parse dates in "DD-MM-YYYY" format.
    function parseDate(dateStr) {
        if (!dateStr) return new Date();
        // Проверка формата даты
        if (dateStr.includes('-')) {
            const parts = dateStr.split('-');
            if (parts.length === 3) {
                // Для "DD-MM-YYYY"
                if (dateStr.match(/^\d{2}-\d{2}-\d{4}$/)) {
                    return new Date(parts[2], parts[1] - 1, parts[0]);
                }
                // Для "YYYY-MM-DD" и ISO форматы
                return new Date(dateStr);
            }
        }
        return new Date(dateStr); // По умолчанию пытаемся создать дату из строки
    }

    // Helper function to calculate days difference between two dates.
    function getDaysDifference(date1, date2) {
        const diffTime = Math.abs(date2 - date1);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    const lastEdited = parseDate(item.last_edited);
    const newDate = parseDate(item.new_date);
    const daysDifference = getDaysDifference(lastEdited, newDate);
    const reasons = [];
    let maxLevel = 0;

    // If item is not edited or there are no significant date differences, return empty fragment.
    if (!item.edited || item.new_date === null || daysDifference < 4 || (daysDifference < 21 && item.cntTHT == 0)) {
        return (<></>);
    }

    // Determine alert levels based on conditions
    if (item.cntTHT === 0 && daysDifference < 300) {
        if (daysDifference > 30) {
            maxLevel = Math.max(maxLevel, 1);
            reasons.push("Geen informatie in de database voor dit artikel");
        }
    } else {
        if (daysDifference > item.maxTHT * 2 + 2) {
            maxLevel = Math.max(maxLevel, 3);
            reasons.push("Meer dan 100% boven de maximale historische THT");
        }

        if (daysDifference > item.avgTHT * 4 + 2) {
            maxLevel = Math.max(maxLevel, 3);
            reasons.push("Meer dan 300% boven de gemiddelde historische THT");
        }

        if (daysDifference > item.maxTHT * 1.25 + 2) {
            maxLevel = Math.max(maxLevel, 2);
            reasons.push("Meer dan 25% boven de maximale historische THT");
        }

        if (daysDifference > item.avgTHT * 2 + 2) {
            maxLevel = Math.max(maxLevel, 2);
            reasons.push("Meer dan 100% boven de gemiddelde historische THT");
        }
    }

    return (
        <div style={styles.thtInfoBox}
            onMouseEnter={() => setHoverVisible(true)}
            onMouseLeave={() => setHoverVisible(false)}>
            <p style={styles.thtInfo}>
                {icons[maxLevel]}
            </p>
            {hoverVisible && maxLevel > 0 && (
                <>
                    <ol style={styles.hoverInfo}>
                        {reasons.map((reason, index) => (
                            <li key={index}>{reason}</li>
                        ))}
                    <br />
                    <p>
                        Gebaseerd op {item.cntTHT} historische THT met een gemiddelde 
                    </p>
                    <p>
                        van {parseFloat(parseFloat(item.avgTHT).toFixed(2))} dagen en een maximum van {parseFloat(item.maxTHT)} dagen.
                    </p>
                    <p>
                        En nu is het {daysDifference} dagen
                    </p>
                    </ol>
                </>
            )}
        </div>
    );
}
