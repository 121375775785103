// ListComponent.jsx
import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

const ListComponent = ({ listId, items }) => {
  return (
    <Droppable droppableId={listId}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps} style={{ padding: '10px', border: '1px solid #ccc', minHeight: '100px' }}>
          {items.map((item, index) => (
            <Draggable key={String(item.id)} draggableId={String(item.id)} index={index}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    userSelect: 'none',
                    margin: '0 0 8px 0',
                    backgroundColor: '#fff',
                    padding: '5px',
                    color: '#333',
                    ...provided.draggableProps.style,
                  }}
                >
                  {item.name}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default ListComponent;