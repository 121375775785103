import React, { createContext, useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { AppNotification } from './AppNotification';

const NotificationContext = createContext(null);


export const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = useState(null);

    const triggerNotification = (message, type) => {
        setNotification({ message, type });
        setTimeout(() => {
            setNotification(null);
        }, 5000);
    };

    return (
        <NotificationContext.Provider value={triggerNotification}>
            {children}
            {notification && ReactDOM.createPortal(
                <AppNotification message={notification.message} type={notification.type} />,
                document.body
            )}
        </NotificationContext.Provider>
    );
};

export const useNotification = () => {
    return useContext(NotificationContext);
};