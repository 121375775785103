// src/components/PrivateRoute.js
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import Login from '../pages/Login';

const PrivateRoute = ({ element: Component }) => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  
  return user ? Component : <Login />;
};

export default PrivateRoute;