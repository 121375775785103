export const AppNotification = ({ message, type }) => {
    const colors = {
        error: 'red',
        success: 'green',
        warning: 'yellow'
    };

    const icons = {
        error: '❗',
        success: '✅',
        warning: '⚠️'
    };

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: colors[type],
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            animation: 'slide-down 1s ease-in-out',
            zIndex: 99999,
            fontSize: '1.2em'
        }}>
            {icons[type]} {message}
        </div>
    );
};