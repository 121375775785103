import React, { useEffect, useState, useCallback } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import ListComponent from '../components/ListComponent';

import { useAuth } from '../auth/AuthContext';
import { fetchCorrectOrder, fetchDatabase } from '../utils';
import { useNotification } from '../components/NotificationContext';

export default function Reorder() {
  const [lists, setLists] = useState({});
  const [correctOrder, setCorrectOrder] = useState([]);
  const [dataBase, setDataBase] = useState([]);

  const notification = useNotification();
  const auth = useAuth();

  const getDatabase = useCallback(() => {
    fetchDatabase(auth, (data) => {
      const tmpData = {};
      data.forEach((item) => {
        tmpData[item.id] = item;
      });
      setDataBase(tmpData);
    }, (err) => {
      console.log("Error fetching database", err);
    });
  }, [auth]);

  const getCorrectOrder = useCallback(() => {
    fetchCorrectOrder(auth, (data) => {
      setCorrectOrder(data);
    }, (err) => {
      console.log("Error fetching correct order", err);
    });
  }, [auth]);

  useEffect(() => {
    getDatabase();
  }, [getDatabase]);

  useEffect(() => {
    getCorrectOrder();
  }, [getCorrectOrder]);

  useEffect(() => {
    if (Object.keys(dataBase).length === 0 || correctOrder.length === 0) {
      return;
    }
    const tmpData = {};
    for (let i = 0; i < correctOrder.length; i++) {
      tmpData[String(i)] = [];
      for (let itemId of correctOrder[i]) {
        const item = dataBase[itemId];
        if (item) {
          tmpData[String(i)].push(item);
        }
      }
    }
    setLists(tmpData);
    //console.log("tmpData", tmpData);
  }, [dataBase, correctOrder]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      // Перетаскивание внутри одного списка
      const items = Array.from(lists[source.droppableId]);
      const [movedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, movedItem);

      setLists((prev) => ({
        ...prev,
        [source.droppableId]: items,
      }));

      // Отправить запрос на изменение позиции внутри одного списка
      sendUpdatePositionRequest(movedItem.id, destination.droppableId, destination.index <= items.length - 2 ? items[destination.index + 1].id : 0)
        .catch(() => {
          getDatabase();
          getCorrectOrder();
          notification('Error moving item', 'warning');
        });
    } else {
      // Перемещение между списками
      const sourceItems = Array.from(lists[source.droppableId]);
      const destItems = Array.from(lists[destination.droppableId]);
      const [movedItem] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, movedItem);

      // Обновление состояния для мгновенного визуального отклика
      setLists((prev) => ({
        ...prev,
        [source.droppableId]: sourceItems,
        [destination.droppableId]: destItems,
      }));

      // Выполнение асинхронного запроса на сервер для изменения списка
      fetch('/api/setProp/meter/' + movedItem.id, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.user.accessToken}`,
        },
        body: JSON.stringify({
          value: parseInt(destination.droppableId) + 1,
        }),
      })
        .then((response) => response.json())
        .then(() => {
          // Отправить запрос на изменение позиции в новом списке
          sendUpdatePositionRequest(movedItem.id, destination.droppableId, destination.index <= destItems.length - 2 ? destItems[destination.index + 1].id : 0)
            .catch(() => {
              getDatabase();
              getCorrectOrder();
              notification('Error moving item', 'warning');
            });
        })
        .catch((error) => {
          console.error('Error moving item:', error);
          // Восстановление первоначального состояния в случае ошибки
          getDatabase();
          getCorrectOrder();
        });
    }
  };

  const sendUpdatePositionRequest = (itemId, meter, itemIdNext) => {
    return fetch('/api/changeOrderWithinMeter/' + itemId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth.user.accessToken}`,
      },
      body: JSON.stringify({
        meter,
        itemIdNext,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log('Position updated successfully', data);
        notification('Position updated successfully', 'success');
      })
      .catch((error) => {
        console.error('Error updating position:', error);
        notification('Error updating position', 'error');
        throw error;
      });
  };

  return (
    <>
      {lists && lists["0"] &&
        <DragDropContext onDragEnd={onDragEnd}>
          <div className='ddc'>
            {
              Object.keys(lists).map((key) => (
                <div key={key}>
                  <h2>Meter {parseInt(key) + 1}</h2>
                  <ListComponent listId={String(key)} items={lists[key]} />
                </div>
              ))
            }
          </div>
        </DragDropContext>
      }
    </>
  );
};
