import React from 'react';

import DailyListTable from '../components/DailyListTable';
import ModalItem from '../components/ModalItem';
import { useAuth } from '../auth/AuthContext';


function ToGoodToGo() {
    const [data, setData] = React.useState({});
    const [correctOrder, setCorrectOrder] = React.useState([]);
    const [processedData, setProcessedData] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);

    const auth = useAuth();

    const fetchDailyList = async () => {
        fetch('/api/getDailyList', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user.accessToken}`
            }
        }).then((res) => res.json())
        .then((data) => {
            const sortedData = {};
            data.forEach(element => {
                sortedData[element.id] = element;
            });
            setData(sortedData);
        }).catch((err) => {
            console.log("Error fetching daily list", err);
            //fetchDailyList();
        });
    }

    const updateDataItem = (item) => {
        const originalItem = data[item.id];

        const requests = [];

        console.log('Update item', item);
        for (const key in item) {
            if (['name', 'order_number', 'img', 'date', "date_delta", "ua", "meter"].indexOf(key) === -1) {
                continue;
            }
            if (originalItem[key] !== item[key]) {
                //console.log('Update', key, item[key]);

                //If date convert 24-04-2024 to 2024-04-24
                if (key === 'date') {
                    if (item[key] === "0") {
                        item[key] = null;
                    } else {
                        const date = item[key].split('-');
                        item[key] = date[2] + '-' + date[1] + '-' + date[0];
                    }
                }

                const request = fetch('/api/setProp/' + key + "/" + item.id, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.user.accessToken}`
                    },
                    body: JSON.stringify({value: item[key]})
                });
                requests.push(request);
            }
        }
        Promise.all(requests).then(() => {
            fetchDailyList();
            fetchCorrectOrder();
        });
    };

    const fetchCorrectOrder = async () => {
        fetch('/api/getCorrectOrder', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user.accessToken}`
            }
        }).then((res) => res.json())
        .then((data) => {
            setCorrectOrder(data);
            //console.log("correctOrder", data)
        }).catch((err) => {
            console.log("Error fetching correct order", err);
            //fetchCorrectOrder();
        });
    }

    const openBeheer = (id) => {
        setSelectedItem(data[id]);
        setShowModal(true);
    }

    const quickAction = (item) => {
    }

    React.useEffect(() => {
        fetchDailyList();
        fetchCorrectOrder();
    }, []);


    React.useEffect(() => {
        const tmpData = []

        if (data && correctOrder) {
            for (const meter of correctOrder) {
                for (const id of meter) {
                    if (data[id]) {
                        while (tmpData.length <= data[id].meter - 1) {
                            tmpData.push([]);
                        }

                        const date = data[id].edited?data[id].new_date:data[id].date;

                        if (date == null) continue;

                        const dateParts = date.split('-');
                        //console.log("date", date);
                        const dateObj = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
                        dateObj.setHours(0, 0, 0, 0);
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);

                        //console.log(dateObj, today);
                        //console.log(dateObj == today);

                        if (dateObj <= today && dateObj >= today) {
                            tmpData[data[id].meter - 1].push({...data[id]});
                        }
                    }
                }
            }
        }
        //console.log("tmpData", tmpData);
        setProcessedData(tmpData);
    }, [data, correctOrder]);


    return (
        <div>
            {processedData && <DailyListTable meters={processedData} openBeheer={openBeheer} quickAction={quickAction} newDate={() => {}} /> }



            {showModal && <ModalItem item={selectedItem} onClick={
                () => {
                    setShowModal(false);
                }
            } updateDataItem={updateDataItem} fetchData={fetchDailyList} />}
        </div>
    );
}

export default ToGoodToGo;