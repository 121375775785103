import React from "react";


function Item({item, onClick}) {

    const handleClick = () => {
        onClick(item);
    };

    return (
        <tr onClick={handleClick}>
            <td>{item.order_number}</td>
            <td>{item.meter}</td>
            <td>{item.name}</td>
            <td>{item.date?item.date:0}</td>
        </tr>
    );
}


function ItemsTable({items, onClick}) {
    return (
        <table>
            <thead>
                <tr>
                    <th>Order number</th>
                    <th>Meter</th>
                    <th>Name</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody>
                {items.map((item, index) => (
                    <Item key={index} item={item} onClick={onClick}/>
                ))}
            </tbody>
        </table>
    );
}


export default ItemsTable;