import React, {useState, useEffect} from "react";
import { useAuth } from "../auth/AuthContext";
import { customFetch } from "../utils";

import ImageUpload from "./ImageUpload";
import { useNotification } from "./NotificationContext";


function formatDateTime(utcTimestamp) {
    // Create a Date object using the UTC timestamp
    const date = new Date(utcTimestamp);

    // Convert to Amsterdam time by adding 2 hours to UTC time
    date.setHours(date.getUTCHours() + 2);

    // Extract the date components
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();

    // Extract the time components
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Return the formatted date and time
    return {
        date: `${day}-${month}-${year}`,
        time: `${hours}:${minutes}`
    };
}



function ModalItem({item, onClick, updateDataItem, fetchData}) {
    const [itemData, setItemData] = useState(item);
    const [currentNewdateTmp, setCurrentNewdateTmp] = useState(item.date || 0);


    const auth = useAuth();
    const notification = useNotification();

    const updateDate = (e) => {
        let date = e.target.value;

        if (date.length == 3) {
            if (date[2] != '-') {
                date = date.substring(0, 2) + '-' + date.substring(2);
            } else {
                date = date.substring(0, 2);
            }
        } 

        if (date.length == 6) {
            if (date[5] != '-') {
                date = date.substring(0, 5) + '-' + date.substring(5);
            } else {
                date = date.substring(0, 5);
            }
        }
        setItemData({...itemData, date: date});
    }






    const updateUa = (e) => {
        setItemData({...itemData, ua: e.target.checked});
    }
    const updateName = (e) => {
        setItemData({...itemData, name: e.target.value});

        if (e.target.value === "00" && itemData.order_number) {
            customFetch('/api/getNameByOrderId/' + itemData.order_number, "GET", null, auth,
                (data) => { 
                    if (data && data.name) {
                        setItemData({...itemData, name: data.name.split(" ").filter((i) => i != '').join(" ")});
                    }
                },
                (err) => {console.log('Error fetching name', err); notification('Naam is niet gevonden', 'error');});
        }
    }
    const updateMeter = (e) => {
        setItemData({...itemData, meter: e.target.value});
    }
    const updateOrderNumber = (e) => {
        setItemData({...itemData, order_number: e.target.value});
    }

    const save = () => {
        updateDataItem(itemData);
        onClick();
    }


    useEffect(() => {
        if (!item || !item.img_path) return;
        setItemData({...itemData, img_path: item.img_path});
    }, [item]);



    return (
        <div className='modal'>
            <div className='modal-content'>
                {itemData &&
                <>
                <div style={
                    {
                        display: 'flex',
                        flexDirection: 'column',
                    }
                }>
                    {itemData.id ? <ImageUpload itemId={itemData.id} imgPath={itemData.img_path?"/api/static/img/" + itemData.img_path:'/api/static/landscape-placeholder.svg'} callback={fetchData}/> : <img src='/api/static/landscape-placeholder.svg' alt='placeholder' width={300} height={300}/>}
                    <label htmlFor="name">Name:</label>
                    <textarea value={itemData.name} onChange={updateName} id="name" {...(!auth.isAdmin() && {disabled: true})}/>
                    <label htmlFor="order_number">Artikelnummer:</label>
                    <input value={itemData.order_number} onChange={updateOrderNumber} id="order_number" {...(!auth.isAdmin() && {disabled: true})}/>
                </div>

                <div>
                    <div>
                        <span>Meter</span>
                        <select value={itemData.meter} onChange={updateMeter} {...(!auth.isAdmin() && {disabled: true})}>

                            {[...Array(17).keys()].map((meter) => {
                                return <option key={meter+1} value={meter+1}>{meter+1}</option>
                            }
                            )}
                        </select>
                    </div>
                    <div className="ua">
                        <span>Uit assortiment</span>
                        <input type='checkbox' checked={itemData.ua?true:false} onChange={updateUa} {...(!auth.isAdmin() && {disabled: true})}/>
                    </div>
                    <div>
                        <span>Date</span>
                        <input type="tel" value={itemData.date == null?0:itemData.date} onChange={updateDate} {...(!auth.isAdmin() && {disabled: true})}/>
                    </div>
                    { itemData.id && itemData.last_modified && itemData.modified_by &&
                    <div className="lastGew">
                        <div>Laatst gewijzigd {formatDateTime(itemData.last_modified).date} {formatDateTime(itemData.last_modified).time}</div>
                        <div>door {itemData.modified_by}</div>
                    </div>
                    }

                </div>
                <div className='right'>
                    <button className='close' onClick={onClick}>
                        <p>
                            &times;
                        </p>
                    </button>

                    <button className='save' onClick={save}>
                        <p>
                            Save
                        </p>
                    </button>
                </div>
                </>
                }
            </div>
        </div>
    );
}


export default ModalItem;