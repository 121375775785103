import React, { useState } from 'react';
import axios from 'axios';

import { useAuth } from '../auth/AuthContext';
import { useNotification } from './NotificationContext';

function ImageUpload({ itemId, imgPath, callback}) {
  const [file, setFile] = useState(null);
  const notification = useNotification();
  const auth = useAuth();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      handleSubmit(file);
    }
  };

  const handleSubmit = async (file) => {
    if (!file) {
      alert('Please select a file first!');
      return;
    }

    const formData = new FormData();
    formData.append('img', file);

    try {
      const response = await axios.post(`/api/addImg/${itemId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization": `Bearer ${auth.user.accessToken}`
        }
      });
      callback();

      const element = document.getElementById('prevIMG');
      if (element) {
        const save = element.src;
        element.src = "";
        setTimeout(() => {
          element.src = save;
        }, 1);
      }
      

      //console.log('Server response:', response.data);
      notification('Image uploaded', 'success');
    } catch (error) {
      console.error('Error uploading image:', error);
      notification('Error uploading image', 'error');
    }
  };

  return (
    <form>
      <label htmlFor="file-input">
          <img id="prevIMG" src={imgPath} width={300} height={300} />
      </label>
      <input
        id="file-input"
        type="file"
        onChange={handleFileChange}
        accept="image/*"
        style={{ display: 'none' }} // Скрыть стандартный input
      />
    </form>
  );
}

export default ImageUpload;
