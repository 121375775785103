import React from "react";
import { Link } from "react-router-dom";



export default function Header() {
    return (
        <header>
            <nav>
                <div className="logo">
                    <h1>Datums</h1>
                </div>
                <ul className="noSelect">
                    <li className="home"><Link to="/">Home</Link></li>
                    <li className="daily-list"><Link to="/daily-list">Daily List</Link></li>
                    <li className="beheer"><Link to="/beheer">Beheer</Link></li>
                    <li className="to-good-to-go"><Link to="/to-good-to-go">To Good To Go</Link></li>
                    <li className="more"><Link to="/">Home</Link></li>
                </ul>
            </nav>
        </header>
    )
}