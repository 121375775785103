// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { NotificationProvider } from './components/NotificationContext';
import { AuthProvider } from './auth/AuthContext';
import Home from './pages/Home';
import PrivateRoute from './components/PrivateRoute';
import Header from './components/Header';

import DailyList from './pages/DailyList';
import Beheer from './pages/Beheer';
import ToGoodToGo from './pages/ToGoodToGo';
import Reorder from './pages/Reorder';
import Photos from './pages/Photos';
import ScrollToTop from './components/ScrollToTop';
import Items from './pages/Items';

function App() {
  return (
    <NotificationProvider>
      <AuthProvider>
        <Router>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<PrivateRoute element={<Home />} />} />
            <Route path="/login" element={<Navigate replace to="/" />} />
            <Route path="/daily-list" element={<PrivateRoute element={<DailyList />} />} />
            <Route path="/beheer" element={<PrivateRoute element={<Beheer />} />} />
            <Route path="/to-good-to-go" element={<PrivateRoute element={<ToGoodToGo />} />} />
            <Route path="/reorder" element={<PrivateRoute element={<Reorder />} />} />
            <Route path="/photos" element={<PrivateRoute element={<Photos />} />} />
            <Route path="/items" element={<PrivateRoute element={<Items />} />} />
            <Route path="*" element={<h1>Not Found</h1>} />
          </Routes>
        </Router>
      </AuthProvider>
    </NotificationProvider>
  );
}

export default App;
